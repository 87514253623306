
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`I collect here some of the tools I write to simplify my daily jobs.`}</p>
    <p>{`Help yourself! I hope you find them useful.`}</p>
    <p><a parentName="p" {...{
        "href": "escape-shell-strings-tool"
      }}>{`Escape Shell Strings (SH, BASH, ZSH)`}</a><br parentName="p"></br>{`
`}{`I use this tool when I need to escape strings for shell commands and I just can’t count all the `}<em parentName="p">{`single quotes`}</em>{`, `}<em parentName="p">{`double quotes`}</em>{` and `}<em parentName="p">{`backslashes`}</em>{` I type.`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    